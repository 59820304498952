<template>
  <h2>PRINTS</h2>
  <div class="content" style="height:100%">
    <div class="imagecontainer" id="about">
          <div><img src="../assets/test6.png"></div>
          <div>
            <p style="margin:0;">All prints are printed on Hahnemühle Photo Rag 308 gsm paper using Giclée reproduction.</p>
            <p style="margin:0;"><ul>
              <li>18" x 12" with .5" margin (45.72cm x 30.48cm with 1.27cm margin) - <b>$75</b></li>
              <li>27" x 18" with 1" margin (68.58cm x 45.72cm with 2.54cm margin) - <b>$115</b></li>
              <li>36" x 24" with 1" margin (91.44cm x 60.96cm with 2.54cm margin) - <b>$200</b></li>
            </ul></p>
            <p style="margin:0;">Shipping is not included and will cost $10 for U.S. shipping and $20 for international.</p><br>
            <p style="margin:0;">More info is provided in the forms below!</p><br>
            <h2 style="margin:0;text-align:left;">SIGNED PRINTS↴</h2><br>
            <p style="margin:0;">Signed prints for Arcadia token holders.</p><br>
            <h1 style="margin:0;"><a href="https://forms.gle/EcWoaA52b9Cb8ZEW8">https://forms.gle/EcWoaA52b9Cb8ZEW8</a></h1><br>
            <h2 style="margin:0;text-align:left;">OPEN EDITION↴</h2><br>
            <p style="margin:0;">Unofficial Arcadia prints from the Goerli testnet. These are not signed.</p><br>
            <h1 style="margin:0;"><a href="https://forms.gle/qC7k1D4KEexQsZjR7">https://forms.gle/qC7k1D4KEexQsZjR7</a></h1><br>
            </div>
      </div>
  </div>
</template>

<style scoped>
h2 {
    font-family: 'Walter Turncoat';
    font-size:40px;
}

@media screen and (max-width: 1700px) {
    h2 {
        font-size:75px;
    }
}
@media screen and (max-width: 1480px) {
    h2 {
        font-size:72px;
    }
}
@media screen and (max-width: 1300px) {
    h2 {
        font-size:65px;
    }
}
@media screen and (max-width: 1100px) {
    h2 {
        margin-left:100px;
        margin-right:100px;
    }
}
@media screen and (max-width: 500px) {
    h2 {
        font-size:60px;
        margin-left:30px;
        margin-right:30px;
    }
}
</style>
